<div class="about-container main-container">
   <div class="mission-container section-container">
      <div class="section-title">
         {{ "about.title" | translate }}
      </div>
      <div class="section-title-border"></div>
      <div class="mission-content">
         <div class="mission-image">
            <img src="../../../assets/media/images/sample_image_8.jpg" class="image">
         </div>
         <div class="mission-text-section">
            <div class="mission-text-container">
               <div class="section-title">
                  {{ "about.mission.title" | translate }}
               </div>
               <div class="mission-text">
                  {{ "about.mission.description.part0" | translate }}
               </div>
               <div class="mission-text">
                  {{ "about.mission.description.part1" | translate }}
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="team-content">
      <div class="team-text-section-wrapper">
         <div class="team-title">
            {{ "about.team.title" | translate }}
         </div>
         <div class="team-text-container">
            <div class="team-text-section">
               <div class="team-text-title">
                  {{ "about.team.description.part0.title" | translate }}
               </div>
               <div class="team-text-description">
                  {{ "about.team.description.part0.description" | translate }}
               </div>
            </div>
            <div class="team-text-section">
               <div class="team-text-title">
                  {{ "about.team.description.part1.title" | translate }}
               </div>
               <div class="team-text-description">
                  {{ "about.team.description.part1.description" | translate }}
               </div>
            </div>
            <div class="team-text-section">
               <div class="team-text-title">
                  {{ "about.team.description.part2.title" | translate }}
               </div>
               <div class="team-text-description">
                  {{ "about.team.description.part2.description" | translate }}
               </div>
            </div>
            <div class="team-text-section">
               <div class="team-text-title">
                  {{ "about.team.description.part3.title" | translate }}
               </div>
               <div class="team-text-description">
                  {{ "about.team.description.part3.description" | translate }}
               </div>
            </div>

         </div>
      </div>
   </div>
   <app-contact-section class="contact-container section-container"></app-contact-section>
</div>