<div class="home-container main-container">
    <div class="welcome-banner">
        <div class="welcome-title">
            {{ "home.welcome.title" | translate }}
        </div>
        <div class="welcome-message">
            {{ "home.welcome.message" | translate }}
        </div>
        <div class="services-link" (click)="onServicesClick()">
            <div class="link-text">
                {{ "home.welcome.serviceLink" | translate }}
            </div>
            >
        </div>
    </div>

    <div class="section-container about-container">
        <div class="section-title">
            {{ "home.about.title" | translate }}
        </div>
        <div class="section-title-border"></div>
        <div class="about-content-container">
            <div class="about-content">
                <div *ngFor="let aboutOption of aboutOptions" class="about-container">
                    <div class="about-image">
                        <img [src]="'../../../assets/media/images/' + aboutOption.image" class="image">
                    </div>
                    <div class="title">
                        {{ aboutOption.title | translate }}
                    </div>
                    <div class="description">
                        {{ aboutOption.description | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div #servicesSection class="section-container services-container">
        <div class="section-title">
            {{ "home.services.title" | translate }}
        </div>
        <div class="section-title-border"></div>
        <div class="services-content">
            <div class="services-image">
                <img src="../../../assets/media/images/services-image.jpg" class="image">
            </div>
            <div class="services-list">
                <div *ngFor="let service of services" class="service-item">
                    {{ service | translate }}
                </div>
            </div>
        </div>
    </div>

    <app-contact-section></app-contact-section>
</div>