import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as components from './index';

const routes: Routes = [
  { path: 'home', component: components.HomeComponent, pathMatch: 'full' },
  { path: 'about', component: components.AboutComponent, pathMatch: 'full' },
  { path: 'services', component: components.ServicesSectionComponent, pathMatch: 'full' },
  { path: 'terms', component: components.TermsComponent, pathMatch: 'full' },
  { path: 'privacy', component: components.PrivacyComponent, pathMatch: 'full' },
  { path: '**',   redirectTo: '/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
