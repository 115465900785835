import { Component, Input, EventEmitter, Output } from '@angular/core';
import { HealthService } from 'src/app/models/health-services.model';
import { ContactInfo, CONTACT_INFO } from 'src/app/models/home.constants';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss']
})
export class ServiceDetailComponent {

  @Input() public selectedService: HealthService;
  @Output() public selectedServiceChange = new EventEmitter<HealthService>();

  public contact: ContactInfo = CONTACT_INFO;

  public showPhoneCopiedMessage: boolean = false;
  public showEmailCopiedMessage: boolean = false;

  constructor(private readonly clipboard: Clipboard) {}

  public getServiceImage(service: HealthService): string {
    return `../../../assets/media/images/services/${service.type}.jpg`;
  }

  public selectService(service?: HealthService): void {
    this.selectedService = service;
    this.selectedServiceChange.emit(this.selectedService);
  }

  public copyEmail(): void {
    this.showEmailCopiedMessage = true;
    this.clipboard.copy(this.contact.email);
    setTimeout(() => this.showEmailCopiedMessage = false, 500);
  }

  public copyPhone(): void {
    this.showPhoneCopiedMessage = true;
    this.clipboard.copy(this.contact.phone);
    setTimeout(() => this.showPhoneCopiedMessage = false, 500);
  }
}
