<div #toolbar id="toolbar">
    <div class="left-side">
        <div class="title" (click)="clickLogo()">
            <!-- <div class="homewellness-logo-container">
               <img [src]="'../../../assets/media/logos/logo_primary.png'" class="homewellness-logo"> 
            </div> -->
            <span>
                {{ 'toolbar.title' | translate }}
            </span>
        </div>
    </div>
    
    <div class="right-side-actions">
        <!-- <div [ngClass]="{ 'hidden': !toolbarService.showBackClick }" class="icon-button back-button x-small"  (click)="backClick()">
            <span class="iconify" data-icon="mdi:arrow-left" data-inline="false"></span>
        </div>  -->

        <div class="toolbar-links">
            <div class="toolbar-link" (click)="onServicesClick()">
                {{ "toolbar.link.services" | translate }}
            </div>
            <div class="toolbar-link" (click)="onAboutClick()">
                {{ "toolbar.link.about" | translate }}
            </div>
        </div>

        <mat-select panelClass="language-select-panel" class="language-select" [(value)]="selectedLanguage" (valueChange)="selectedLanguageChange($event)">
            <mat-option *ngFor="let language of languageOptions" [value]="language">
                {{ language | uppercase }}
            </mat-option>
        </mat-select>

        <div [matMenuTriggerFor]="menu" class="burger-menu icon-button">
            <span class="iconify" data-icon="mdi:menu" data-inline="false"></span>
        </div>
        <mat-menu #menu="matMenu">
            <div mat-menu-item class="toolbar-link" (click)="onServicesClick()">
                {{ "toolbar.link.services" | translate }}
            </div>
            <div mat-menu-item class="toolbar-link" (click)="onAboutClick()">
                {{ "toolbar.link.about" | translate }}
            </div>
        </mat-menu>
    </div>
</div>