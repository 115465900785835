import { Injectable } from '@angular/core';
import { AppLanguage } from '../models/language.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {

    public defaultLanguage: AppLanguage = AppLanguage.PT;
    public selectedLanguage: AppLanguage;
    public languageOptions: AppLanguage[] = [
        AppLanguage.EN,
        AppLanguage.PT
    ];
    public localStorageLanguageKey = 'app-selected-language';

    constructor(private readonly translate: TranslateService) {
        const savedLanguage = localStorage.getItem(this.localStorageLanguageKey);
        const savedOption = this.languageOptions.find(o => o === savedLanguage);
        this.selectLanguage(savedOption ?? this.defaultLanguage);
    }
  
    public selectLanguage(language: AppLanguage): void {
        const hasOption = this.languageOptions.find(o => o === language);
        if (!hasOption) {
            // Selected option not available
            return;
        }
        this.selectedLanguage = language
        localStorage.setItem(this.localStorageLanguageKey, this.selectedLanguage);
        this.translate.setDefaultLang(language);
        this.translate.use(language);
    }
}