
export enum HealthServiceType {
    GERERAL = 'general',
    GASTO = 'gastro',
    PNEUMO = 'pneumo',
    ENDOCRI = 'endocri',
    CARDIO = 'cardio',
    GINECO = 'gineco',
    OBSTETRI = 'obstetri',
    UROLO = 'urolo',
    REUMATO = 'reumato',
    NEURO = 'neuro',
    NEFRO = 'nefro',
    ONCO = 'onco',
    PEDIAT = 'pediat'
}

export interface HealthService {
    type: HealthServiceType,
    tele: boolean,
    inPerson: boolean
}

export enum AppQueryParamType {
    HEALTH_CARE_SERVICE = 'hcs'
}