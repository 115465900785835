<div class="section-container contact-container">
   <div class="section-title">
       {{ "home.contact.title" | translate }}
   </div>
   <div class="section-title-border"></div>
   <div class="contact-content">
       <div class="contact-info-card">
           <div *ngIf="contact.phone" class="contact-detail" (click)="copyPhone()">
               <div class="icon-button x-small">
                   <span class="iconify" data-icon="mdi:phone" data-inline="false"></span>
               </div>
               <div class="contact">
                   {{ contact.phone }}
               </div>
               <div *ngIf="showPhoneCopiedMessage" class="copied">
                   {{ "common.copied" | translate }}
               </div>
           </div>
           <div *ngIf="contact.email" class="contact-detail" (click)="copyEmail()">
               <div class="icon-button x-small">
                   <span class="iconify" data-icon="mdi:email" data-inline="false"></span>
               </div>
               <div class="contact">
                   {{ contact.email }}
               </div>
               <div *ngIf="showEmailCopiedMessage" class="copied">
                   {{ "common.copied" | translate }}
               </div>
           </div>
           <div class="availability">
               {{ "home.contact.availability" | translate }}
           </div>
       </div>
   </div>
</div>