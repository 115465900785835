<!-- Footer -->
<div class="footer">
  <div class="title" (click)="clickLogo()">
      <!-- <div class="homewellness-logo-container">
        <img [src]="'../../../assets/media/logos/drawnbro_logo_100px.png'" class="homewellness-logo"> 
      </div> -->
      <span>{{ "toolbar.title" | translate }}</span>
  </div>

  <div class="footer-items">
    <a href="/privacy">{{ "footer.privacy" | translate }}</a>

    <a href="/terms">{{ "footer.terms" | translate }}</a>

    <div class="footer-copyright text-center py-3">
      © 2023 Copyright:
      <a href="https://www.homewellness.pt/">www.homewellness.pt</a>
    </div>
  </div>
</div>
