import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppQueryParamType, HealthService, HealthServiceType } from 'src/app/models/health-services.model';

@Component({
  selector: 'app-service-section',
  templateUrl: './services-section.component.html',
  styleUrls: ['./services-section.component.scss']
})
export class ServicesSectionComponent implements OnInit, OnDestroy {

  public services: HealthService[] = this.loadServices();
  public selectedService: HealthService;

  public showPhoneCopiedMessage: boolean = false;
  public showEmailCopiedMessage: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(private router: Router, private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.subscriptions.push(this.route.queryParams.subscribe((queryParams: Params) => this.handleQueryParamsChange(queryParams)))
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public getServiceImage(service: HealthService): string {
    return `../../../assets/media/images/services/${service.type}.jpg`;
  }

  public selectService(service?: HealthService): void {
    this.selectedService = service
    this.router.navigate([], { queryParams: {
      [AppQueryParamType.HEALTH_CARE_SERVICE]: service?.type
    }})
  }

  private handleQueryParamsChange(queryParams: Params): void {
    const selectedServiceIdQueryParam = queryParams[AppQueryParamType.HEALTH_CARE_SERVICE]
    console.log('selectedServiceIdQueryParam:', selectedServiceIdQueryParam)
    if (selectedServiceIdQueryParam != this.selectedService?.type) {
      if (!selectedServiceIdQueryParam) {
        this.selectedService = null
      } else {
        this.selectedService = this.services?.find(service => service.type == selectedServiceIdQueryParam)
      }
    }
  }

  private loadServices(): HealthService[] {
    const services: HealthService[] = [];
    const activeServiceTypes: HealthServiceType[] = [
      HealthServiceType.GERERAL,
      HealthServiceType.GASTO,
      HealthServiceType.PNEUMO,
      HealthServiceType.ENDOCRI,
      HealthServiceType.CARDIO,
      HealthServiceType.GINECO,
      HealthServiceType.OBSTETRI,
      HealthServiceType.UROLO,
      HealthServiceType.REUMATO,
      HealthServiceType.NEURO,
      HealthServiceType.NEFRO,
      HealthServiceType.ONCO,
      HealthServiceType.PEDIAT
    ];
    // TODO: define types that are tele
    // TODO: define types that are in person
    for (const activeServiceType of activeServiceTypes) {
      services.push({
        type: activeServiceType,
        tele: true,
        inPerson: true
      });
    }
    return services;
  }
}
