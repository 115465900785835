import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { ContactInfo, CONTACT_INFO } from 'src/app/models/home.constants';

interface AboutUsItem {
  title: string
  description: string
  image: string
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  public showPhoneCopiedMessage: boolean = false;
  public showEmailCopiedMessage: boolean = false;

  public contact: ContactInfo = CONTACT_INFO;

  public services: string[] = [
    "home.services.services.0",
    "home.services.services.1",
    "home.services.services.2",
    "home.services.services.3",
    "home.services.services.4",
    "home.services.services.5",
    "home.services.services.6",
    "home.services.services.7",
  ];

  public aboutOptions: AboutUsItem[] = [
    {
      title: "home.about.sections.0.title",
      description: "home.about.sections.0.description",
      image: "about_us_0.jpg"
    },
    {
      title: "home.about.sections.1.title",
      description: "home.about.sections.1.description",
      image: "about_us_1.jpg"
    },
    {
      title: "home.about.sections.2.title",
      description: "home.about.sections.2.description",
      image: "about_us_2.jpg"
    }
  ]

  constructor(public readonly router: Router, private readonly clipboard: Clipboard) { }

  public scrollToDiv(target:HTMLElement):void {
    // Scroll to the target element smoothly
    target.scrollIntoView();
  }

  public copyEmail(): void {
    this.showEmailCopiedMessage = true;
    this.clipboard.copy(this.contact.email);
    setTimeout(() => this.showEmailCopiedMessage = false, 500);
  }

  public copyPhone(): void {
    this.showPhoneCopiedMessage = true;
    this.clipboard.copy(this.contact.phone);
    setTimeout(() => this.showPhoneCopiedMessage = false, 500);
  }

  public onServicesClick(): void {
    this.router.navigate(['services']);
  }
}
