<div *ngIf="!selectedService" class="services-container main-container">
   <div class="section-title">
      {{ "services.title" | translate }}
   </div>
   <div class="section-title-border"></div>
   <div class="service-list-container">
      <div class="service-list">
         <div *ngFor="let service of services" class="service-card" (click)="selectService(service)">
            <div class="card-image">
               <img [src]="getServiceImage(service)" class="image">
            </div>
            <div class="card-content">
               <div class="card-title">{{ 'services.list.' + service.type + '.title' | translate }}</div>
               <!-- <div class="card-description">{{ 'services.list.' + service.type + '.description' | translate }}</div> -->
               <div class="card-button">
                  {{ 'services.actions.book' | translate }}
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<div *ngIf="selectedService" class="services-container main-container">
   <app-service-detail
      [(selectedService)]="selectedService"
      (selectedServiceChange)="selectService($event)"
      >
   </app-service-detail>
</div>