import { Component } from '@angular/core';
import { ContactInfo, CONTACT_INFO } from 'src/app/models/home.constants';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.scss']
})
export class ContactSectionComponent {

  public contact: ContactInfo = CONTACT_INFO;

  public showPhoneCopiedMessage: boolean = false;
  public showEmailCopiedMessage: boolean = false;

  constructor(private readonly clipboard: Clipboard) {}

  public copyEmail(): void {
    this.showEmailCopiedMessage = true;
    this.clipboard.copy(this.contact.email);
    setTimeout(() => this.showEmailCopiedMessage = false, 500);
  }

  public copyPhone(): void {
    this.showPhoneCopiedMessage = true;
    this.clipboard.copy(this.contact.phone);
    setTimeout(() => this.showPhoneCopiedMessage = false, 500);
  }
}
