import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'homewellness-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

    constructor(
        public router: Router,
    ) {}

    public clickLogo(): void {
        this.router.navigate(['home']);
    }

    public openNewTab(url: string): void {
        window.open(url, '_blank');
      }
}
