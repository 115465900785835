<div class="section-title" (click)="selectService()">
   <div class="breadcrumb-text">{{ "services.title" | translate }}</div>
</div>
<div class="section-title-border"></div>
<div class="service-detail-container">
   <div class="service-detail-content">
      <div class="service-detail-text-section">
         <div class="service-detail-text-container">
            <div class="section-title">
               {{ 'services.list.' + selectedService.type + '.title' | translate }}
            </div>
            <div class="service-detail-text">
               {{ 'services.list.' + selectedService.type + '.description' | translate }}
            </div>
         </div>
      </div>
      <div class="service-detail-image">
         <img [src]="getServiceImage(selectedService)" class="image">
      </div>
   </div>
   <app-contact-section class="contact-section"></app-contact-section>
</div>