import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppLanguage } from 'src/app/models/language.model';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'homewellness-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

    public selectedLanguage;

    public languageOptions = [
        AppLanguage.EN,
        AppLanguage.PT
    ]
    constructor(
        public readonly router: Router,
        private readonly languageService: LanguageService
    ) {
        this.selectedLanguage = this.languageService.selectedLanguage;
    }

    public clickLogo(): void {
        this.router.navigate(['home']);
    }

    public openNewTab(url: string): void {
        window.open(url, '_blank');
    }

    public selectedLanguageChange(selectedLanguage): void {
        this.languageService.selectLanguage(selectedLanguage);
        this.selectedLanguage = this.languageService.selectedLanguage;
    }

    public onServicesClick(): void {
        this.router.navigate(['services']);
    }

    public onAboutClick(): void {
        this.router.navigate(['about']);
    }
}
